import mutationTypes from './mutation-types'
import {
  reviewStates,
  autoMergeStates,
  dupeSetsStates,
} from '@/api/dupe-sets/constants'
import {
  getResolvedStats,
  getDupeSetStats,
  getJobsStats,
} from '@/api/dupe-sets/stats'
import {
  getDupeSets,
  getDupeSetsForBulkReview,
} from '@/api/dupe-sets/dupe-sets'
import { getJobs } from '@/api/dupe-sets/jobs'
import { getMergeErrors } from '@/api/dupe-sets/errors'
import {
  getFilterFields,
  getFilterOperators,
  getFilterUsCountries,
} from '@/api/dupe-sets/mocks'
import axios from 'axios'

export default {
  async getResolvedStats({ commit, dispatch }, payload) {
    try {
      const response = await getResolvedStats(payload)

      commit(mutationTypes.SET_RESOLVED_STATS, response.resolved_dupeSets)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getDupeSetsToBulkReview({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSetsForBulkReview({
        ...payload,
        reviewStates: [reviewStates.NOT_REVIEWED],
        states: [
          dupeSetsStates.CREATED,
          dupeSetsStates.MODIFIED,
          dupeSetsStates.VIEWED,
          dupeSetsStates.NOTIFIED,
        ],
      })

      commit(mutationTypes.SET_BULK_DUPE_SETS, response)
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          'app/setCurrentError',
          { message: error.message },
          { root: true }
        )
      }

      throw error
    }
  },
  async setDupeSetsBulkStats({ commit }, stats) {
    commit(mutationTypes.SET_BULK_DUPE_SETS_STATS, { stats })
  },
  async getDupeSetsToReview({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSets({
        ...payload,
        reviewStates: [reviewStates.NOT_REVIEWED],
        states: [
          dupeSetsStates.CREATED,
          dupeSetsStates.MODIFIED,
          dupeSetsStates.VIEWED,
          dupeSetsStates.NOTIFIED,
        ],
      })

      commit(mutationTypes.SET_DUPE_SETS_TO_REVIEW, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getDupeSetsToReviewStats({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSetStats({
        ...payload,
        reviewStates: [reviewStates.NOT_REVIEWED],
        states: [
          dupeSetsStates.CREATED,
          dupeSetsStates.MODIFIED,
          dupeSetsStates.VIEWED,
          dupeSetsStates.NOTIFIED,
        ],
      })

      commit(mutationTypes.SET_DUPE_SETS_TO_REVIEW_STATS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async getAutoMergesToReview({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSets({
        ...payload,
        autoMergeStates: [autoMergeStates.WAITING_APPROVAL],
        states: [
          dupeSetsStates.CREATED,
          dupeSetsStates.MODIFIED,
          dupeSetsStates.VIEWED,
        ],
      })

      commit(mutationTypes.SET_AUTOMERGES_TO_REVIEW, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getAutoMergesToReviewStats({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSetStats({
        ...payload,
        autoMergeStates: [autoMergeStates.WAITING_APPROVAL],
        states: [
          dupeSetsStates.CREATED,
          dupeSetsStates.MODIFIED,
          dupeSetsStates.VIEWED,
        ],
      })

      commit(mutationTypes.SET_AUTOMERGES_TO_REVIEW_STATS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async getMergedDupeSets({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSets({
        ...payload,
        states: [dupeSetsStates.FINISHED, dupeSetsStates.HIGH_MCS_FINISHED],
      })

      commit(mutationTypes.SET_MERGED_DUPESETS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getMergedDupeSetsStats({ commit, dispatch }, payload = {}) {
    try {
      const response = await getDupeSetStats({
        ...payload,
        states: [dupeSetsStates.FINISHED, dupeSetsStates.HIGH_MCS_FINISHED],
      })

      commit(mutationTypes.SET_MERGED_DUPESETS_STATS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getJobsStats({ commit, dispatch }) {
    try {
      const response = await getJobsStats()

      commit(mutationTypes.SET_JOBS_STATS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getJobs(
    { commit, dispatch },
    { page, searchString, type, status } = {}
  ) {
    try {
      const { jobs, perPage, totalItems, totalPages } = await getJobs({
        page,
        searchString,
        type,
        status,
      })

      commit(mutationTypes.SET_JOBS, { jobs, perPage, totalItems, totalPages })
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async getFilterFields({ commit, dispatch }) {
    try {
      const data = await getFilterFields()

      commit(mutationTypes.SET_FILTER_FIELDS, data)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getFilterOperators({ commit, dispatch }) {
    try {
      const data = await getFilterOperators()

      commit(mutationTypes.SET_FILTER_TEXT_OPERATORS, data.text)
      commit(mutationTypes.SET_FILTER_DATE_OPERATORS, data.date)
      commit(mutationTypes.SET_FILTER_NUM_OPERATORS, data.number)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  async getFilterOptions({ commit, dispatch }) {
    try {
      const data = await getFilterUsCountries()

      commit(mutationTypes.SET_FILTER_OPTIONS_STATES, data)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },

  async getMergeErrors(
    { commit, dispatch },
    { page, searchField, searchString } = {}
  ) {
    try {
      const { errors, perPage, totalItems, totalPages } = await getMergeErrors({
        page,
        searchField,
        searchString,
      })

      commit(mutationTypes.SET_MERGE_ERRORS, {
        errors,
        perPage,
        totalItems,
        totalPages,
      })
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
}
